<template>
    <component :is="SkinComponent" v-model="board" :code="code" @save="validate"></component>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        login: { type: Boolean, default: null },
        secret: { type: Boolean, default: null },

        _id: String,

        scope: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            board: {
                code: this.$props.code,

                subject: null,
                content: null,
                summary: null,

                password: null,
                thumb: null,

                writer: {
                    name: null,
                },
            },
        };
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload || {};
        },
        _board() {
            return this.$route.params._board;
        },
    },

    created() {
        this.init();
    },

    methods: {
        async init() {
            try {
                if (this.login && !this.accessToken) throw new Error("로그인 후 작성 할 수 있습니다");
                if (this._board) {
                    const { board } = await api.v1.boards.get({ _id: this._board });
                    if (board?.writer?._id !== this.$store?.state?.payload?._user) throw new Error("권한이 없습니다.");

                    if ((board?.files || []).length > 0) board.files = await Promise.all(board.files.map(async ({ url }) => await api.getResource(url, true)));
                    this.board = board;
                }
            } catch (error) {
                console.error(error);
                alert(error.message);

                this.$router.go(-1);
                return;
            }
        },

        validate() {
            try {
                if (this.secret) {
                    if (this.board.password == null || this.board.password.length !== 6) throw new Error("비밀번호는 6자리 숫자여야 합니다.");
                }

                this.save();
            } catch (error) {
                this.handleError(error);
            }
        },

        async save() {
            try {
                let { secret, password, _files = [], files = [], ...board } = this.board;

                // password ENCRYPT
                if (secret) password = CryptoAES.encrypt(password);
                else password = undefined;

                // _files DELETE
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.v1.boards.files.delete({ _id })));

                delete board._comments;
                delete board.comments;

                // document POST/PUT
                [{ board }] = [!!this._board ? await api.v1.boards.put({ ...board, password }) : await api.v1.boards.post({ ...board, password })];

                // files POST
                for (const [index, file] of files.entries()) {
                    await api.v1.boards.files.post({ _board: board._id, index }, file);
                }

                if (this.board.thumb) await api.v1.boards.postThumb(board, this.board.thumb);

                alert("저장되었습니다");
                this.$router.go(-1);
            } catch (error) {
                this.handleError(error);
            }
        },

        handleError(error) {
            console.error(error);
            alert(error.repsonse ? error.response.data.message : error.message);
        },
    },
};
</script>
